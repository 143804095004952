<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>

  <div id="maincontent_container">
    <submenu-server menu="server"></submenu-server>

      <div class="submenu-content">
        <div v-if="error" class="alert alert-danger">{{ error }}</div>

        <!-- show page content -->
        <div v-if="item">

          <!-- page title -->
          <h2 class="section-lead">{{item.intro}}</h2>

          <div v-for="b in item.body">

            <div v-if="b.type === 'heading'">
              <div v-if="b.value === 'Partner Vendors'">
                <!-- <h2 class="vendors-lead">{{b.value}}</h2> -->
              </div>
              <div v-else-if="b.value === 'Inquiry Button'" class="mb-5">
                <router-link class="btn btn-primary btn-lg" to="/contact-us/server">Inquiry/Setup Account</router-link>
              </div>
              <div v-else>
                <p class="lead">{{b.value}}</p>
              </div>
            </div>

            <div v-else-if="b.type === 'paragraph'" class="inquiry">
              <p v-html="b.value"></p>
            </div>

            <!-- <div v-else-if="b.type === 'image'">
              <img
              :src="b.value.large.src"
              :width="b.value.large.width"
              :height="b.value.large.height">
            </div> -->

            <!-- <div v-else-if="b.type === 'block'">
              <div>
                <div>
                  <img :src="b.value.image.medium.src"
                  :width="b.value.image.medium.width"
                  :height="b.value.image.medium.height">
                </div>
                <div class="underline"><h6>{{b.value.heading}}</h6></div>
                <p v-html="b.value.paragraph"></p>
              </div>
            </div> -->

            <!-- stream block -->
            <div v-else-if="b.type === 'stream_block'">
            <div style="display: table;">
              <div v-for="sb in b.value">
                
                <!-- partner vendors logo list -->
                <div v-if="sb.value.name==='vendor'" class="logos">
                    <!-- <img v-for="itm in sb.value.image_stream" :src="itm.value.medium.src"> -->
                </div>
                
                  <!-- images at the page bottom -->
                  <div v-else-if="sb.value.name === 'footer'">
                    <div v-for="itm in sb.value.image_stream" class="float-start pad">
                      <img :src="itm.value.large.src" class="bot-img">
                    </div>
                  </div>

                <!-- list of servers -->
                <div v-else>
                  <!-- <div class="block">
                    <div v-for="(itm, index) in sb.value.image_stream">
                      1. product image
                      <div style="display: block;">
                        <img v-if="index === 0" :src="itm.value.medium.src" class="server-image">
                        2. logo image
                        <img v-else :src="itm.value.medium.src" class="img-logo">
                      </div>
                    </div>
                    <div v-if="sb.value.heading" class="underline heading" style="clear: both">{{sb.value.heading}}</div>
                    <div v-if="sb.value.paragraph" v-html="sb.value.paragraph" class="paragraph"></div>
                  </div> -->
                </div>
              </div>

            </div>
            </div>
            <!-- end of stream block-->
            
          </div>
        </div>
        <!-- end of page content -->

  </div>
  </div>

</template>

<script>
import SubmenuServer from '../../components/nav/SubmenuServer.vue'
import BreadcrumbPage from "../../components/BreadcrumbPage.vue"
import axios from 'axios'
import { ref, onMounted } from 'vue'
import { getAPI } from '../../utils/axios-api'

export default {
  name: 'ServerSolutions',
  components: { SubmenuServer, BreadcrumbPage },
  setup() {
    const error = ref('')
    const item = ref({})

    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "Server", link: ""})
    breadcrumb_links.value.push({text: "Server Solutions", link: "ServerSolutions"})

    getAPI
    .get('/content/pages/39')
    .then(response => {
      item.value = response.data
    })
    .catch(err => {
      error.value = err
    })

    return { error, item, breadcrumb_links }
  }
}
</script>

<style scoped>
  .section-lead {
    font-family: Roboto;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 40px;
    color: #555;
  }
  .lead{
    margin-bottom: 10px;
    font-family: Roboto;
    color: #555;
    font-size: 26px;
    font-weight: bold;
    /* text-transform: uppercase; */
  }
  .heading {
    margin-top: 50px;
    margin-bottom: 20px;
    padding: 10px 0;
    color: #555;
    font-family: Segoe;
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    border-bottom: 1px solid #333;
  }

  .server-image {
    display: block;
    max-width: 225px;
    height: 200px;
    margin: 40px auto;
  }
  .img-logo {
    float: right;
    width: auto;
    height: 44px;
    margin: 0;
  }

  .submenu-content .logos {
    margin: 40px auto;
  }
  .vendors-lead {
    margin-top: 40px;
    padding-bottom: 20px;
    font-family: Segoe;
    color: #555;
    font-size: 26px;
    font-weight: bold;
    border-bottom: 1px solid #555;
  }
  .submenu-content .logos img {
    width: 175px;
    margin: 10px 5%;
  }

  .submenu-content .inquiry:last-child() {
    margin: 40px 0 20px;
  }
  .submenu-content .inquiry:last-child() a {
    color: #337ab7;
    text-decoration: underline;
  }
  .inquiry-btn {
    display: block;
    margin: 0 0 70px;
    padding: 15px 100px;
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;
    background: #00509f;
    border-radius: 0;
  }
  .inquiry-btn:hover {
    color: #fff;
  }


  .block {
    display: block;
    padding: 20px;
    border: none;
  }
  @media (min-width: 1035px) {
    .submenu-content .logos img {
      margin: 0 20px;
    }
    .submenu-content .inquiry:last-child() {
      width: 75%;
    }
    .block {
      float: left;
      width: 33.33%;
      height: 1000px;
    }
  }

  .float-start {
    width: 50%;
    margin-bottom: 80px;
  }
  .bot-img {
    width: calc(100% - 10px);
  }
  #maincontent_container > div.submenu-content > div > div:nth-child(11) > div > div > div > div > div:nth-child(1) > img {
    margin: 0 10px 0 0;
  }
  #maincontent_container > div.submenu-content > div > div:nth-child(11) > div > div > div > div > div:nth-child(2) > img {
    margin: 0 0 0 10px;
  }
</style>